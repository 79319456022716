var render = function () {
  var _vm$myOpponent, _vm$myOpponent$partic, _vm$myOpponent2, _vm$myOpponent3, _vm$myOpponent4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-2 h-100 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$myOpponent = _vm.myOpponent) === null || _vm$myOpponent === void 0 ? void 0 : (_vm$myOpponent$partic = _vm$myOpponent.participant) === null || _vm$myOpponent$partic === void 0 ? void 0 : _vm$myOpponent$partic.avatar_image,
      "variant": "light-primary",
      "size": "50px"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('h4', {
    staticClass: "mb-2 text-truncate"
  }, [_vm._v(" " + _vm._s((_vm$myOpponent2 = _vm.myOpponent) !== null && _vm$myOpponent2 !== void 0 && _vm$myOpponent2.participant ? (_vm$myOpponent3 = _vm.myOpponent) === null || _vm$myOpponent3 === void 0 ? void 0 : _vm$myOpponent3.participant.name : (_vm$myOpponent4 = _vm.myOpponent) === null || _vm$myOpponent4 === void 0 ? void 0 : _vm$myOpponent4.name) + " ")])])], 1), _c('b-row', {
    staticClass: "mb-1 flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-secondary"
    }
  }, [_vm._v(" Match ready! ")])], 1)], 1), _c('b-row', {
    staticClass: "mt-auto"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleMatchInfoClick
    }
  }, [_vm._v(" Open my match ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }