<template>
  <div
    style="height: inherit"
  >
    <match-info-modal :match-id="selectedMatchId" />

    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Tournament matches
          </h5>
        </b-col>
        <b-col
          sm="12"
          md="6"
          class="d-flex justify-content-end"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search participant ..."
              size="lg"
              type="search"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                height="20px"
                icon="SearchIcon"
                width="20px"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row
      v-if="matchesAllLoading"
      class="text-center p-2"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <!-- My matches -->
    <b-row
      v-if="!matchesAllLoading && showMatchReadyInfoCard && tournament?.status_id !== TournamentStatusType.FINISHED"
      class="mb-1 flex-column"
    >
      <b-col>
        <h5>My matches:</h5>
      </b-col>

      <!-- Match ready info card -->
      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <b-card
          class="p-2 h-100 m-0"
          no-body
          style="min-height: 233px"
        >
          <div class="d-flex justify-content-center flex-column flex-grow-1">
            <div class="text-center">
              When match is ready it will be displayed here.
            </div>
            <div class="text-center mt-2">
              <feather-icon
                icon="BookmarkIcon"
                size="50"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <!-- Match ready -->
      <b-col
        v-for="match in myReadyMatches"
        :key="match.id"
        sm="12"
        md="6"
        lg="4"
      >
        <tournament-match-ready-card
          :match="match"
          @onMatchInfoClick="handleMatchInfoClick"
        />
      </b-col>
    </b-row>

    <!-- All tournament matches -->

    <b-row v-if="!matchesAllLoading">
      <!-- Matches list filter -->
      <b-col class="d-flex justify-content-center justify-content-md-start">
        <b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="filter"
            button-variant="outline-primary"
            :options="filterOptions"
            buttons
            name="radios-btn-default"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <vue-perfect-scrollbar
      class="scroll-area"
      :settings="perfectScrollbarSettings"
    >
      <b-row v-if="!matchesAllLoading">
        <!-- Matches list -->
        <b-col
          v-for="match in matches"
          :key="match.id"
          class="d-flex mb-1"
          md="6"
          sm="12"
          lg="3"
        >
          <tournament-match-card
            :key="match.id"
            :match="match"
            @onMatchInfoClick="handleMatchInfoClick"
          />
        </b-col>

        <!-- No matches  -->
        <b-col
          v-if="!matchesAllLoading && (!matches || matches?.length === 0)"
          sm="12"
        >
          <div class="d-flex justify-content-center flex-column">
            <div class="font-italic m-2 d-flex justify-content-center">
              <feather-icon
                icon="InfoIcon"
                size="40"
              />
            </div>
            <div class="d-flex flex-column align-items-center ">
              <p>No matches found.</p>
              <p
                v-if="tournament?.status_id === TournamentStatusType.PUBLISHED"
                class="font-italic "
              >
                Matches are going to appear once tournament starts
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

  </div>
</template>
<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BFormRadioGroup, BInputGroup, BInputGroupAppend, BRow, BSpinner,
} from 'bootstrap-vue'
import TournamentMatchCard from '@/views/tournament/TournamentMatchCard.vue'
import MatchStatusType from '@/constants/MatchStatusType'
import { filterMyMatches, myTeamsAreParticipatingInTournament } from '@/views/tournament/tournament-utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import MatchInfoModal from '@/views/tournament/MatchInfoModal.vue'
import TournamentMatchReadyCard from '@/views/tournament/TournamentMatchReadyCard.vue'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'

export default {
  components: {
    TournamentMatchReadyCard,
    MatchInfoModal,
    TournamentMatchCard,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormGroup,
    VuePerfectScrollbar,
  },
  mixins: [breadcrumbManager],
  data() {
    return {
      MatchStatusType,
      selectedMatchId: null,
      matches: [],
      matchesAll: [],
      matchesAllLoading: false,
      myMatches: [],
      searchQuery: '',
      filter: 'all',
      perfectScrollbarSettings: {
        suppressScrollX: true,
      },
      filterOptions: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Upcoming',
          value: 'upcoming',
        },
        {
          text: 'Finished',
          value: 'finished',
        },
      ],
    }
  },
  computed: {
    TournamentStatusType() {
      return TournamentStatusType
    },
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },
    showMatchReadyInfoCard() {
      return myTeamsAreParticipatingInTournament(this.tournament) === true
    },
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },
    myReadyMatches() {
      if (!this.myMatches || this.myMatches.length === 0) return []

      return this.myMatches?.filter(match => match.status_id === MatchStatusType.READY)
    },
  },
  watch: {
    async searchQuery(searchValue) {
      if (!searchValue) {
        this.matches = this.matchesAll

        return
      }

      this.matches = this.matches?.filter(
        match => match.opponent1?.participant?.name?.toLowerCase().includes(searchValue.toLowerCase())
            || match.opponent1?.name?.toLowerCase().includes(searchValue.toLowerCase())
            || match.opponent2?.participant?.name?.toLowerCase().includes(searchValue.toLowerCase())
            || match.opponent2?.name?.toLowerCase().includes(searchValue.toLowerCase()),
      )
    },
    async filter(value) {
      if (!value || value === 'all') {
        this.matches = this.matchesAll
      } else {
        if (this.filter === 'upcoming') {
          this.matches = this.matchesAll.filter(match => match.status_id === MatchStatusType.READY)
        }

        if (this.filter === 'finished') {
          this.matches = this.matchesAll.filter(match => match.status_id === MatchStatusType.COMPLETE)
        }
      }
    },
  },
  async created() {
    this.matchesAll = await this.fetchMatches()

    this.matches = this.matchesAll

    if (this.matchesAll.length !== 0) {
      this.myMatches = await filterMyMatches(this.matchesAll)
    }

    await this.hydrateBreadcrumbs()
  },
  methods: {
    async hydrateBreadcrumbs() {
      const data = await this.$store.getters['tournament/getTournament']
      if (data != null) {
        this.replaceBreadcrumb({
          find: 'tournamentName',
          replace: {
            text: data?.name,
            loading: false,
          },
        })
      }
    },
    participatingInTournament: myTeamsAreParticipatingInTournament,
    handleMatchInfoClick(matchId) {
      this.selectedMatchId = matchId
      this.$bvModal.show('match-info-modal')
    },
    async fetchMatches() {
      this.matchesAllLoading = true

      const { data } = await this.$api.tournament.fetchTournamentMatches(this.$route.params.id)

      this.matchesAllLoading = false

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  height: calc(100% - 155px);
  padding-right: 1rem;
}
</style>
