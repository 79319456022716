<template>
  <b-card
    no-body
    class="p-2 h-100 w-100"
  >
    <b-row class="flex-nowrap">
      <b-col
        sm="5"
        class="text-center"
      >
        <b-avatar
          :src="myOpponent?.participant?.avatar_image"
          :variant="`light-primary`"
          class="mb-2"
          size="50px"
        />
      </b-col>

    </b-row>

    <b-row class="flex-nowrap">
      <b-col
        sm="5"
        class="text-center"
      >
        <h4 class="mb-2 text-truncate">
          {{
            myOpponent?.participant
                ? myOpponent?.participant.name
                : myOpponent?.name
          }}
        </h4>
      </b-col>
    </b-row>

    <!-- Match status -->
    <b-row class="mb-1 flex-nowrap">
      <b-col class="text-center">
        <b-badge
          class="ml-1"
          pill
          variant="light-secondary"
        > Match ready!
        </b-badge>
      </b-col>
    </b-row>

    <!-- Match info redirect  -->
    <b-row class="mt-auto">
      <b-col class="text-center">
        <b-button
          size="sm"
          variant="primary"
          @click="handleMatchInfoClick"
        >
          Open my match
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow, BButton, BBadge, BAvatar,
} from 'bootstrap-vue'
import MatchStatusType from '@/constants/MatchStatusType'
import store from '@/store'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    BButton,
    BAvatar,
  },
  props: {
    match: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      MatchStatusType,
      myOpponent: null,
    }
  },
  watch: {
    // eslint-disable-next-line consistent-return
    match(newMatch) {
      const me = store.getters['user/getMe']
      if (!me) {
        return false
      }

      if (
        (newMatch?.opponent1?.participant?.participant_type === 'player' && newMatch?.opponent1?.participant?.participant_id === me.id)
          || (newMatch?.opponent1?.participant?.participant_type === 'team' && me?.teams.some(team => newMatch?.opponent1?.participant?.participant_id === team.id))
      ) {
        this.myOpponent = newMatch?.opponent2
      } else if (newMatch?.opponent2?.participant?.participant_type === 'player' && newMatch?.opponent2?.participant?.participant_id === me.id) {
        this.myOpponent = newMatch?.opponent1
      }
    },
  },
  methods: {
    handleMatchInfoClick() {
      this.$emit('onMatchInfoClick', this.match.id)
    },
  },
}
</script>

<style lang="scss">

</style>
